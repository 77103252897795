import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
import { ContentfulGeneralPageType } from "../types/contentful.types"
import ContentfulRichText from "../utilities/contentful-rich-text"
import { isDesktop, isMobile, isTablet } from "react-device-detect"
import AdjustLink from "./adjust-link"

// !IMPORTANT
// Background Image of the hero is coming from page template to cover all the header

const Hero = ({ data, onDownloadClick }) => {
  const {
    text,
    imageNearTheText,
    imageNearTheTextPosition,
  }: ContentfulGeneralPageType["hero"] = data
  const { pathname } = useLocation()

  const HeroContent = () => {
    return (
      <>
        <ContentfulRichText className='text-white text-center' raw={text} />
        <div className='flex items-center justify-center'>
          {isMobile || isTablet ? (
            <AdjustLink className='glow-animation hero-cta-btn'>
              get the app
            </AdjustLink>
          ) : (
            <button
              className='cursor-pointer glow-animation hero-cta-btn'
              onClick={onDownloadClick}
            >
              get the app
            </button>
          )}
        </div>
      </>
    )
  }

  return (
    <div className={`hero${imageNearTheText ? " hero-image-near" : ""}`}>
      {imageNearTheText ? (
        <div className='hero-top'>
          <div className=''>
            <GatsbyImage
              image={
                getImage(
                  imageNearTheText.localFile.childImageSharp.gatsbyImageData
                )!
              }
              alt=''
            />
          </div>
          <div className='hero-content mb-10 xl:mb-0 md:px-10'>
            <HeroContent />
          </div>
        </div>
      ) : (
        <div className='hero-content'>
          <HeroContent />
        </div>
      )}
    </div>
  )
}
export default Hero
