import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import useQueryParams from "query-params-hook";
import SEO from "./seo";
import Footer from "./footer";
import AdjustLink from "./adjust-link";
import { useLocation } from "@reach/router"

const TemplateWrapper = ({ seo, impressionLink, children }: { seo: any; impressionLink?: string, children: any }) => {
  const location = useLocation()
  const { lp: lpPages } = useQueryParams();
  const [isLpPage, setIsLpPage] = useState(lpPages === "true");
  const [className, setClassName] = useState('');

  useEffect(() => {
    setIsLpPage(lpPages === "true");
    setClassName(lpPages === "true" ? "lp-pages" : `general-pages ${location.pathname.includes("/slot-games")? 'slot-games':''}`);
  }, [lpPages]);

  return (
    <div className={className}>
      <SEO isLpPage={isLpPage} data={seo} impressionLink={impressionLink} />

      {children}

      {!isLpPage ? (
        <Footer />
      ) : (
        <AdjustLink className="block w-fit my-10 mx-auto">
          <StaticImage
            className="mr-6"
            src="../../images/cta.png"
            alt="Play Now"
            width={150}
          />
        </AdjustLink>
      )}
    </div>
  );
};

export default TemplateWrapper;
